/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'house-heart': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 6.982C9.664 5.309 13.825 8.236 8 12 2.175 8.236 6.336 5.309 8 6.982"/><path pid="1" d="M8.707 1.5a1 1 0 00-1.414 0L.646 8.146a.5.5 0 00.708.707L2 8.207V13.5A1.5 1.5 0 003.5 15h9a1.5 1.5 0 001.5-1.5V8.207l.646.646a.5.5 0 00.708-.707L13 5.793V2.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v1.293zM13 7.207V13.5a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5V7.207l5-5z"/>',
    },
});
